import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { NotFound } from "../components/graphics/NotFound";
import { Layout } from "../components/layout";
import { SEO } from "../components/SEO";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Page not found" />
      <NotFound sx={{ height: "unset", width: "100%", marginTop: { xs: "30%", md: "4%" } }} />
      <Box textAlign="center" marginTop={8}>
        <Typography variant="h1" textTransform="uppercase">
          Error
        </Typography>
        <Typography variant="h3" textTransform="uppercase" marginTop={3}>
          Page not found
        </Typography>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
